import { Injectable } from '@angular/core';
import { Roles, RoleAccess } from '../models/roles.model';

@Injectable()
export class RoleService {
  public roles: Roles = {
    1: {
      role: 'Admin',
      defaultRoute: '/user',
      routeAccess: {
        user:true,
        profile: true,
        project: true,
        unit: true,
        task: true,
        team: true,
        shift: true,
        templateTasks:true
      }
    },
    2: {
      role: 'Sales',
      defaultRoute: '/user',
      routeAccess: {
        user:true,
        profile: true,
        project: true,
        unit: true,
        task: true,
        team: true,
        shift: true,
        templateTasks:true
      }
    },
    3: {
      role: 'Employee',
      defaultRoute: '/user',
      routeAccess: {
        user:true,
        profile: true,
        project: true,
        unit: true,
        task: true,
        team: true,
        shift: true,
        templateTasks:true
      }
    }
    

  };

  constructor() { }

  getAccess(role: number): RoleAccess {
    return <RoleAccess>this.roles[role];
  }
}
