import { Routes } from '@angular/router';
//Layouts
import { 
  SimplyWhiteLayout,
  BlankSimplywhiteComponent
} from './@pages/layouts';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';


export const AppRoutes: Routes = [

  {
    path: '',
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout
  },
  {
    path: 'project',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/projects/project.module#ProjectModule"
  },
  {
    path: 'user',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/user/user.module#UserModule"
  },
  {
    path: 'unit',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/unit/unit.module#UnitModule"
  },
  {
    path: 'team',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/team/team.module#TeamModule"
  },
  {
    path: 'session',
    data: {
        breadcrumb: 'Home'
    },
    component: BlankSimplywhiteComponent,
    loadChildren: "./view/session/session.module#SessionModule"
  },
  {
    path: 'profile',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/profile/profile.module#ProfileModule"
  },
  {
    path: 'task',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/task/task.module#TaskModule"
  },
  {
    path: 'shift',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/shifts/shift.module#ShiftModule"
  },
  {
    path: 'templateTasks',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/settings/tasks/task.module#TaskModule"
  }
];
