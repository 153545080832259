
import { Component, OnInit,Input, ViewEncapsulation, HostListener } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'pg-menu-items',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('toggleHeight', [
        state('close', style({
            height: '0',
            overflow: 'hidden',
            marginBottom:'0',
            display:'none',
           
        })),
        state('open', style({
            display:'block',
            height: '*',
            marginBottom:'10px',
           // overflow: 'hidden',
        })),
        transition('close => open', animate('140ms ease-in')),
        transition('open => close', animate('140ms ease-out'))
    ])
  ],
  encapsulation:ViewEncapsulation.None 
})
export class MenuComponent implements OnInit {
  menuItems = [];
  currentItem = null;
  isPerfectScrollbarDisabled = false
  public config: PerfectScrollbarConfigInterface = {};
  constructor(
    private authService : AuthService,
    private router : Router
  ) { }

  ngOnInit() {
    for(let i of this.menuItems){
      i.toggle = 'close';
    }
  }

  isActive(url){
    if(url){
      if(this.router.url.includes(url)){
        return true;
      }
    }
    return false;
  }

  openLink(item,optItem?){
    if(optItem){
      optItem['toggle'] = optItem['toggle'] == 'open' ? 'close' : 'open';
    }
    if(item == undefined){
      return;
    }
    this.router.navigate([item]);
  }

  logout(){
    this.authService.signout();
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      this.togglePerfectScrollbar();
    })
  }

  @HostListener("window:resize", [])
	onResize() {
    this.togglePerfectScrollbar();
	}

  togglePerfectScrollbar(){
    this.isPerfectScrollbarDisabled = window.innerWidth < 1025
  }

  @Input()
  set Items(value) {
    this.menuItems = value
  }

  switch(item){
    console.log(this.menuItems[item]);
    if(this.menuItems[item].toggle !=='open'){
      console.log("switched on!");
      this.menuItems[item].toggle = 'open';
    }
    else{
      this.menuItems[item].toggle = 'close';
    }
  }
  
  toggleNavigationSub(event,item) {
      event.preventDefault();
      if(this.currentItem && this.currentItem != item){
        this.currentItem["toggle"] = 'close';
      }
      this.currentItem = item;
      item.toggle = (item.toggle === 'close' ? 'open' : 'close');
  }
}
