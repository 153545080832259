import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';
import * as $ from 'jquery';

@Component({
  selector: 'simplywhite-layout',
  templateUrl: './simplywhite.component.html',
  styleUrls: ['./simplywhite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimplyWhiteLayout extends RootLayout implements OnInit {

  errors:boolean = false;

  menuLinks = [
    {
      label: "Projects",
      routerLink: "/project",
      roles:[1,2,3],
      iconType: "fal",
      iconName: "fa-building",
    },
    {
      label: "Units",
      routerLink: "/unit",
      roles:[1,2],
      iconType: "fal",
      iconName: "fal fa-house",
    },
    {
      label: "Tasks",
      routerLink: "/task",
      roles:[1,2,3],
      iconType: "fal",
      iconName: "fal fa-tasks",
    },
    {
      label: "Teams",
      routerLink: "/team",
      roles:[1,2,3],
      iconType: "fal",
      iconName: "fal fa-users",
    },
    {
      label: "Employees",
      routerLink: "/user",
      roles:[1,2],
      iconType: "fal",
      iconName: "fal fa-user-friends",
    },
    {
      label: "Settings",
      roles:[1],
      iconType: "fal",
      iconName: "fa-cogs",
      toggle: "close",
      submenu: [
        {
          label: "Tasks",
          roles:[1],
          routerLink: "/templateTasks",
          iconType: "fal",
          iconName: "fa-tasks",
        }
      ]
    },
    // {
    //   label: "Support",
    //   routerLink: "/support",
    //   roles:[1,2,3],
    //   iconType: "fal",
    //   iconName: "fas fa-phone",
    // },
  ];
  ngOnInit() {
    this.changeLayout("menu-pin");
    //Will sidebar close on screens below 1024
    this.autoHideMenuPin();
    document.documentElement.style.setProperty('--primary-color', '#546E87');
    document.documentElement.style.setProperty('--secondary-color', '#7DA1B5');
    this.filterMenu(this.authService);
  }

  getLogo(){
    return this.authService.getCompanyLogo();
  }

  signout(){
    this.authService.signout();
  }

  isSystemAdmin() : boolean{
    if(this.authService.getUserRole() == 1){
      return true;
    }
    return false;
  }

  getFullName(){
    if(!this.authService.user){
      return "";
    }
    return this.authService.user.first_name+' '+this.authService.user.last_name;
  }

  filterMenu(authService){
    let role = authService.getUserRole();
    for(let parent = 0; parent < this.menuLinks.length;parent++){
        if(!this.menuLinks[parent].roles.includes(role)){
            this.menuLinks[parent] = null;
        }
    }
    this.menuLinks = this.menuLinks.filter(Boolean);
}

}
