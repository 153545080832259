import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { LocalStoreService } from './local-store.service';

import { environment } from '../../../environments/environment';
import { UserService } from './user.service';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Only for demo purpose
  authenticated = false;
  token : any;
  isForgotPassword = false;
  user:UserModel;
  private companyLogo:string ="/assets/img/fd_logo.png";

  constructor(
    private http: HttpClient,
     private store: LocalStoreService,
     private userService : UserService,
     private router : Router) {
    this.checkAuth();
  }

  timeout;

  authenticate(){
    let _this = this;
    if(_this.timeout){
      clearTimeout(_this.timeout);
    }
    _this.timeout = setTimeout(() => {
      _this.userService.get(_this.getUserId()).subscribe(res => {
        _this.user = res['data'];
      },
      err => {
        _this.signout();
      });
    }, 350);
  }

  checkAuth() {
    this.authenticated = !!this.store.getItem('currentUser');
  }

  getUser() {
    return of(this.store.getItem('currentUser'));
  }

  getUserId(){
    const user : any = this.store.getItem('currentUser');
    if(!user){
      return null;
    }
    return user.id;
  }

  getCompanyLogo(){
    return "/assets/img/fd_logo.png";
  }

  getSystemRoles(){
    return this.http.get(`${environment.baseUrl}/systemroles`);
  }

  getUserRole() {
    const user: any = this.store.getItem('currentUser');
    if(!user){
      return null;
    }
    return user.system_role;
  }

  forgotToken(username){
    return this.http.post(`${environment.baseUrl}/auth/forgot/request`,{email:username});
  }

  forgotUpdate(formData){
    return this.http.post(`${environment.baseUrl}/auth/forgot/reset`,formData);
  }

  updateCredentials(formData){
    return this.http.put(`${environment.baseUrl}/user/profile`,formData);
  }

  signin(credentials) {
    return this.http.post<any>(`${environment.baseUrl}/auth/login`, { email: credentials.email, password: credentials.password })
      .pipe(map(res => {
        console.log("UserLogin: ",res);
        const user = res.data.user;
        const token = res.data.api_key;
        const id = res.data.user.id;
        const system_role = res.data.system_role.id;
        const companies = res.data.companies;
        this.token = token;
          // login successful if there's a jwt token in the response
          if (user && token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              this.store.setItem('currentUser', {...user, token,system_role,id,companies});
              this.authenticated = true;
          }

          return user;
      }));
  }
  signout() {
    this.authenticated = false;
    this.store.setItem('currentUser', null);
    this.router.navigateByUrl('/session/login');
  }
}
